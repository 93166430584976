


































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { ValidateFileType } from "@/interfaces/global_valley/global_valley";
import { PostResume } from "@/interfaces/global_valley/resume_parser/post_resume";
import {
  GET_POST_RESUME_LOADING,
  POST_RESUME
} from "@/store/modules/resume_parser/constants";
import { UploadedFile } from "@/interfaces/global_valley/global_valley";

export default Vue.extend({
  name: "PostResume",
  data(): PostResume {
    return {
      resume_files: [],
      files: [],
      drop_file_image: require("@/assets/illustrations/file.svg"),
      is_valid: false,
      file_id: 1
    };
  },
  computed: {
    ...mapGetters("resume_parser", {
      post_resume_loading: GET_POST_RESUME_LOADING
    })
  },
  methods: {
    ...mapActions("resume_parser", {
      post_resume: POST_RESUME
    }),
    /**
     * Process user selected files, through drag & drop
     * @param value: DragEvent
     */
    process_dropped_files(value: DragEvent) {
      if (value && value.dataTransfer) {
        const files: File[] = Array.from<File>(value.dataTransfer.files);
        this.init_uploaded_files(files);
      }
    },
    /**
     * Process user selected files, through file uploader
     * @param files: File[] => Selected files
     */
    process_selected_files(files: File[]) {
      this.init_uploaded_files(files);
    },
    init_uploaded_files(files: File[]) {
      for (let file of files) {
        const valid_file = this.validate_file(file);
        const obj: UploadedFile = {
          file,
          error: !valid_file.valid,
          error_message: valid_file.msg,
          id: this.file_id
        };
        this.resume_files.push(obj);
        this.file_id += 1;
      }
      this.valid_files();
    },
    /**
     * Validate selected file type: .docs, .pdf & .txt are valid types
     * @param file: File => selected file
     * @return boolean
     */
    validate_file(file: File): ValidateFileType {
      if (file.size > 3e7) {
        return {
          valid: false,
          msg: "File size should be less than 30 MB!"
        };
      } else if (
        file.type === "text/plain" ||
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/doc" ||
        file.type === "application/ms-doc" ||
        file.type === "application/msword"
      ) {
        return {
          valid: true,
          msg: ""
        };
      } else {
        return {
          valid: false,
          msg: "Unsupported file type. .pdf, .docx, .txt files are allowed"
        };
      }
    },
    /**
     * Convert file size from bytes to MB
     * @param size: number file size
     * @return string file size in MB
     */
    get_file_size(size: number): string {
      return (size / (1024 * 1024)).toFixed(2);
    },
    remove_resume_file(file: UploadedFile) {
      this.resume_files = this.resume_files.filter(
        (_file) => _file.id !== file.id
      );
      this.valid_files();
    },
    valid_files() {
      this.is_valid = true;
      for (let file of this.resume_files) {
        if (file.error) {
          this.is_valid = false;
          break;
        }
      }
    },
    /**
     * Submit form
     */
    async submit_form() {
      const obj = new FormData();
      this.resume_files.map((a) => {
        obj.append("candidate_resumes", a.file);
        return a.file;
      });
      await this.post_resume(obj);
    }
  }
});
